/* eslint-disable no-unused-vars */
import React, { Suspense, useState } from "react";
import { useEffect } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import configDB from "../data/customizer/config";
import PrivateRoute from "./private-route";
import Signin from "../auth/signin";
import AppLayout from "../components/app";
import { routes } from "./layouts-routes";
import { Loader } from "react-feather";

const MainRoutes = () => {
  const [currentUser, setCurrentUser] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    const abortController = new AbortController();
    // setAuthenticated(JSON.parse(localStorage.getItem("authenticated")));
    setAuthenticated(sessionStorage.getItem('login'));
		const color = configDB.data.color.color;
    const layout = configDB.data.color.layout_version;
    document.body.classList.add(layout);
    console.ignoredYellowBox = ["Warning: Each", "Warning: Failed"];
    console.disableYellowBox = true;
    document.getElementById("color").setAttribute("href", `${process.env.PUBLIC_URL}/assets/css/${color}.css`);

    return function cleanup() {
      abortController.abort();
    };
  }, []);

  return (
    <>
      <BrowserRouter basename="/">
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route path="/" element={<PrivateRoute />}>
              {currentUser !== null || authenticated || true ? (
                <>
									<Route exact path={`${process.env.PUBLIC_URL}`} element={<Navigate to={'/dashboard'} />} />
                  <Route exact path={`/`} element={<Navigate to={`${process.env.PUBLIC_URL}/dashboard`} />} />
                </>
              ) : (
                ""
              )}
              {routes.map(({ path, Component }, i) => (
                <Route element={<AppLayout />} key={i}>
                  <Route exact path={path} element={Component} />
                </Route>
              ))}
            </Route>

            <Route exact path={`${process.env.PUBLIC_URL}/login`} element={<Signin />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </>
  );
};

export default MainRoutes;
