import { fetchPost } from '../utilities/utilities.js';
import { PUB_PATH } from '../utilities/globals';

export {
	doLogin
}

export default {
	doLogin
}

async function doLogin (username, password) {
	return fetchPost(`${PUB_PATH}/login`, { username, password });
}
