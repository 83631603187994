import {
	SET_USER_DATA,
	CLEAR_USER_DATA,
	SET_SELECTED_BUSINESS
} from '../../redux/actionTypes'


const initial_state = {
	name: '',
	email: '',
	businesses: [],
	currentBusinessId: null
}

const userReducer = (state = initial_state, action) => {

    switch (action.type) {
		case SET_USER_DATA: {
			if (!action.payload.currentBusinessId) {
				action.payload.currentBusinessId = action.payload.businesses[0]?.businessId
			}
			return { ...state, ...action.payload};
		}
		case CLEAR_USER_DATA: {
            return { ...state, ...initial_state };
		}

		case SET_SELECTED_BUSINESS: {
			return { ...state, currentBusinessId: action.payload.BusinessId };
		}

        default: return { ...state };
    }

}
export default userReducer
