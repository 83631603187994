import React, { Fragment, useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { Form } from "reactstrap";
import { setSelectedBusiness } from '../../../redux/user/action.js';

const BusinessSelector = props => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const businesses = useSelector(store => store.User.businesses);
	const currentBusinessId = useSelector(store => store.User.currentBusinessId);
	const [formdata, setFormdata] = useState({ businessId: currentBusinessId });
	const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm({
		defaultValues: {
			businessId: `${currentBusinessId}`
		}
	});

	useEffect(() => {
		register("businessId", { required: true });
	}, [register]);

	function suppressSubmit() {
		return false;
	}

	function changeOption(e) {
		const selectedBusinessId = e.target.value;
		setValue("businessId", selectedBusinessId);  // Update form value
		// console.log('Selected Business ID: ', selectedBusinessId);
		dispatch(setSelectedBusiness(selectedBusinessId));
		navigate('/dashboard');
	}

	return (
		<Fragment>
			{(businesses.length > 1) && (
				<div className="container-fluid mt-30 mb-30">
					<div className="row">
						<div className="col">
							<Form onSubmit={handleSubmit(suppressSubmit)}>
								<select
									className="form-control"
									id="businessId"
									name="businessId"
									onChange={changeOption}
									defaultValue={currentBusinessId}
								>
									{businesses.map(biz => (
										<option key={biz.businessId} value={biz.businessId}>{biz.businessName}</option>
									))}
								</select>
							</Form>
						</div>
					</div>
				</div>
			)}
		</Fragment>
	)
}

export default BusinessSelector;
